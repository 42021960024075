export const Gallery = (props) => {
  return (
    <div id='portfolio' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Gallery</h2>
          <p>
            Please checkout our past work bellow:
          </p>
        </div>
        <div className='row'>
          <div className='portfolio-items'>
            <div className='col-sm-12 col-md-6 col-lg-4'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                    <div className='hover-text'>
                      <h4>LED HUB UK</h4>
                      <p>Website Created with WordPress</p>
                    </div>
                    <img
                      src='img/portfolio/LED HUB UK.fw.png'
                      className='img-responsive'
                      alt='Project Title'
                    />
                </div>
              </div>
            </div>
            <div className='col-sm-12 col-md-6 col-lg-4'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                    <div className='hover-text'>
                      <h4>Mercato.pk</h4>
                      <p>Logo creation</p>
                    </div>
                    <img
                      src='img/portfolio/final-mercato-2.png'
                      className='img-responsive'
                      alt='Project Title'
                    />
                </div>
              </div>
            </div>
            <div className='col-sm-12 col-md-6 col-lg-4'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                    <div className='hover-text'>
                      <h4>Mercato</h4>
                      <p>Website created with WordPress</p>
                    </div>
                    <img
                      src='img/portfolio/Mercato.fw.png'
                      className='img-responsive'
                      alt='Project Title'
                    />
                </div>
              </div>
            </div>
            <div className='col-sm-12 col-md-6 col-lg-4'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  <div className='hover-text'>
                      <h4>Zoha Rehman</h4>
                      <p>Logo creation</p>
                    </div>
                    <img
                      src='img/portfolio/Zoha Rehman Logo.fw.png'
                      className='img-responsive'
                      alt='Project Title'
                    />
                </div>
              </div>
            </div>
            <div className='col-sm-12 col-md-6 col-lg-4'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  <div className='hover-text'>
                      <h4>Zoha Rehman</h4>
                      <p>Website created with WordPress</p>
                    </div>
                    <img
                      src='img/portfolio/Zoha Rehman.fw.png'
                      className='img-responsive'
                      alt='Project Title'
                    />
                </div>
              </div>
            </div>
            <div className='col-sm-12 col-md-6 col-lg-4'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  <div className='hover-text'>
                      <h4>Sign Hub</h4>
                      <p>Logo Creation</p>
                    </div>
                    <img
                      src='img/portfolio/Sign Hub Logo.jpeg'
                      className='img-responsive'
                      alt='Project Title'
                    />
                </div>
              </div>
            </div>
            <div className='col-sm-12 col-md-6 col-lg-4'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  <div className='hover-text'>
                      <h4>Sign Hub</h4>
                      <p>Website created with WordPress</p>
                    </div>
                    <img
                      src='img/portfolio/Sign Hub Website.fw.png'
                      className='img-responsive'
                      alt='Project Title'
                    />
                </div>
              </div>
            </div>
            <div className='col-sm-12 col-md-6 col-lg-4'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  <div className='hover-text'>
                      <h4>Sign Hub Email</h4>
                      <p>Email template created in html to be used in gmail for marketing</p>
                    </div>
                    <img
                      src='img/portfolio/Sign Hub Email.fw.png'
                      className='img-responsive'
                      alt='Project Title'
                    />
                </div>
              </div>
            </div>
            <div className='col-sm-12 col-md-6 col-lg-4'>
              <div className='portfolio-item'>
                <div className='hover-bg'>
                  <div className='hover-text'>
                      <h4>Sign Hub Logo Redesign</h4>
                      <p>Logo Creatioun using Corel Draw</p>
                    </div>
                    <img
                      src='img/portfolio/Sign Hub Logo New Colourful_1.png'
                      className='img-responsive'
                      alt='Project Title'
                      style={{width: 60 + '%', margin: "auto"}}
                    />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
