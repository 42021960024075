import ReactHtmlParser from 'react-html-parser';

export const Services = (props) => {

  function handleClick(elementPassed) {
    //alert("Something was passed in: " + elementPassed);
    document.getElementById('message').innerHTML = ('I would like to ask you about ' + elementPassed + ' ...\n');
  }

  return (
    <div id='services' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Our Services</h2>
          <p>
            Checkout some of the services we offer:
          </p>
        </div>
        <div className='row'>
          <div>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-sm-12 col-md-6 col-lg-4'>
                  {' '}
                  <i className={d.icon}></i>
                  <div className='service-desc'>
                    <h3>{d.name}</h3>
                    <p>{ReactHtmlParser(d.text)}</p>
                    <a class='btn btn-custom btn-lg' onClick={handleClick.bind(this, d.name)} href='#contact'>{d.contactText}</a>
                  </div>
                </div>
              ))
            : 'loading'}
          </div>
        </div>
      </div>
    </div>
  )
}
